




















import { Component, Vue } from 'nuxt-property-decorator';
import { gsap } from 'gsap';

@Component
export default class ProfileCarousel extends Vue {
  currentIndex = 0;
  duration = 4;
  imageSizes = 'sm:70px md:120px lg:150px xl:180px 2xl:240px 3xl:320px';

  images = [
    {
      url: '/images/profiles/albin.jpg',
      alt: '',
      width: '620',
      height: '413',
    },
    {
      url: '/images/profiles/alexander.jpg',
      alt: '',
      width: '620',
      height: '415',
    },
    {
      url: '/images/profiles/christine.jpg',
      alt: '',
      width: '620',
      height: '424',
    },
    {
      url: '/images/profiles/ella.jpg',
      alt: '',
      width: '620',
      height: '415',
    },
    {
      url: '/images/profiles/felicia.jpg',
      alt: '',
      width: '620',
      height: '413',
    },
    {
      url: '/images/profiles/markus.jpg',
      alt: '',
      width: '620',
      height: '413',
    },
    {
      url: '/images/profiles/viktor.jpg',
      alt: '',
      width: '620',
      height: '413',
    },
    {
      url: '/images/profiles/tor.jpg',
      alt: '',
      width: '620',
      height: '397',
    },
  ];

  mounted () {
    this.randomizeStartIndex();
    gsap.delayedCall(this.duration, this.nextImage);
  }

  randomizeStartIndex () {
    this.currentIndex = Math.floor(Math.random() * this.images.length);
  }

  nextImage () {
    this.currentIndex++;
    if (this.currentIndex >= this.images.length)
      this.currentIndex = 0;
    gsap.delayedCall(this.duration, this.nextImage);
  }
}
