import { render, staticRenderFns } from "./ProfileCarousel.vue?vue&type=template&id=16baa029&"
import script from "./ProfileCarousel.vue?vue&type=script&lang=ts&"
export * from "./ProfileCarousel.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileCarousel.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResponsiveImage: require('/Users/markussh/git/theodora-tech-website/src/components/ResponsiveImage.vue').default})
